import cookie from "cookie";
import { isStandalone } from "./utils/detectors";

export const IS_PROD = process.env.REACT_APP_ENVIRONMENT === "production";
export const IS_LOCAL = process.env.REACT_APP_ENVIRONMENT === "local";
export const IS_LOCAL_API =
  window.location.host === "web.41u4.test" ||
  window.location.host === "test.41u4.com";

export const DOMAIN_COOKIE = IS_PROD
  ? ".alua.com"
  : IS_LOCAL || IS_LOCAL_API
    ? null
    : ".41u4.com";
export const DEFAULT_EXPIRY_DAYS_COOKIE = 1460;
export const URL_YII = IS_PROD
  ? "https://alua.com"
  : IS_LOCAL_API
    ? "https://41u4.test"
    : "https://41u4.com";
export const URL_LOGIN = IS_LOCAL || isStandalone ? "/login" : URL_YII;

const getApiRoot = (): string => {
  let apiRoot = "https://api.alua.com";

  const apiRootFromCookie = cookie.parse(document.cookie)[
    "custom_api_base_url"
  ];
  if (apiRootFromCookie) {
    apiRoot = apiRootFromCookie;
  } else {
    if (!IS_PROD) {
      if (IS_LOCAL_API) {
        apiRoot = "https://api.41u4.test";
      } else if (window.location.host === "web0.41u4.com") {
        apiRoot = "https://api0.41u4.com";
      } else if (window.location.host === "sp19-web.41u4.com") {
        apiRoot = "https://sp19-api.41u4.com";
      } else {
        apiRoot = "https://api.41u4.com";
      }
    }
  }

  return apiRoot;
};
export const API_ROOT = getApiRoot();

const getMediaRoot = (): string => {
  let mediaRoot = "https://upload.alua.com";

  // for temporary test
  // TODO: remove this
  if (window.location.host === "beta.alua.com") {
    return "https://upload2.alua.com";
  }

  if (!IS_PROD) {
    if (IS_LOCAL_API) {
      mediaRoot = "https://upload.41u4.test";
      // } else if (window.location.host === "web0.41u4.com") {
      //   mediaRoot = "https://api0.41u4.com";
    } else if (window.location.host === "sp19-web.41u4.com") {
      mediaRoot = "https://sp19-upload.41u4.com";
    } else {
      mediaRoot = "https://upload.41u4.com";
    }
  }

  return mediaRoot;
};
export const MEDIA_ROOT = getMediaRoot();

const getWsRoot = (): string => {
  let wsRoot = "wss://ws.alua.com:8443";

  const wsRootFromCookie = cookie.parse(document.cookie)["custom_ws_base_url"];
  if (wsRootFromCookie) {
    wsRoot = wsRootFromCookie;
  } else {
    if (!IS_PROD) {
      if (IS_LOCAL_API) {
        wsRoot = "wss://ws.41u4.test";
      } else if (window.location.host === "web0.41u4.com") {
        wsRoot = "wss://ws0.41u4.com:8443";
      } else if (window.location.host === "sp19-web.41u4.com") {
        wsRoot = "wss://sp19-ws.41u4.com:8443";
      } else {
        wsRoot = "wss://ws.41u4.com:8443";
      }
    }
  }

  return wsRoot;
};
export const WS_ROOT = getWsRoot();

export const AUTH_TOKEN_NAME = "auth-token";

export const DEFAULT_JSON_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const CHANGE_CHAT_RATE_LIMIT_IN_SECONDS = IS_PROD ? 48 * 60 * 60 : 60;

export const GIPHY_API_KEY = process.env.REACT_APP_GIPHY_API_KEY;

// Viewport sizes

export const FROM_SM_MOBILE = 350;
export const FROM_MD_MOBILE = 400;
export const FROM_LG_MOBILE = 480;
export const FROM_TABLET = 768;
export const FROM_LAPTOP = 1152;
export const FROM_DESKTOP = 1440;
export const FROM_WIDE = 1920;

export const ALUA_SUPPORT_USERNAME = "aluasupport";
export const ALUA_SUPPORT_USER_ID = IS_PROD
  ? "57da87bc933d4c000f1dbc69"
  : "5c18b1fae1bfd776f7392a52";
export const ALUA_INFO_USERNAME = "aluainfo";

export const DEFAULT_ERROR_MESSAGE =
  "Something went wrong. Please try again later.";

export const MAX_PROFILE_PHOTOS_COUNT = 12;

export const USDT_TO_CREDIT_RATIO = 0.18;

export const TRUSTPILOT_LINK = "https://www.trustpilot.com/review/alua.com";
